@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    

    
    

}

html{
    scroll-behavior: smooth;
    

}

body{
    font-family: 'Roboto', sans-serif;
    font-family: 'Open Sans', sans-serif;

}

.container{
    max-width: 1170px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    overflow-x: hidden;

}
.bgc{
    /* background-color: #f7f2fd; */
    min-width: auto;

}

section{
    padding: 50px 0;
}

.sticky_header{
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 3px 3px -8px 3px #ddd;
    z-index: 99;
    background-color: rgba(226, 218, 217, 0.8);
    backdrop-filter: blur;
}

@media only screen and (max-width: 767px) {
    .menu{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #00000084;
        z-index: 100;
        display: none;
    }
    .menu .nav_menu{
        width: 15rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
        background: #e2dad9;
        flex-direction: column;
        line-height: 30px;
        justify-content: center;
        align-items: center;

    }
    .show_menu{
        display: block;

    }
    
}







/* __________________________MAIN HEADER______________________ */
.fadeIn {
    animation: fadeInAnimation 2s ease-in-out alternate infinite;
  }
  
  @keyframes fadeInAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(15px); /* Adjust the value to control the movement distance */
    }
  }







/* _____________________HEADERS___________________ */


  .fadeOut {
    animation: fadeOutAnimation 2s ease-in-out alternate infinite;
  }
  
  @keyframes fadeOutAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(15px); /* Adjust the value to control the movement distance */
    }
  }


  /* @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .bg-gradient-animation {
    background-size: 200% 200%;
    animation: gradientAnimation 5s linear infinite;
  }
  


 */
         /* _________________ABOUT_________________ */
 @keyframes slideUpper {
  0% { transform: translateX(0); }
  100% { transform: translateX(-15px); } /* Adjust the value to control the distance */
}

@keyframes slideBottom {
  0% { transform: translateX(0); }
  100% { transform: translateX(15px); } /* Adjust the value to control the distance */
}

.animate-slide-upper {
  animation: slideUpper 2s infinite alternate;
}

.animate-slide-bottom {
  animation: slideBottom 2s infinite alternate;
}


/* __________________SERVICES ICONS________________ */

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.scale-icon {
  animation: scaleAnimation 2s infinite; /* Adjust the animation duration if needed */
}


.navi.active{
  /* border-top: solid 2px #8873ef; */
  text-decoration: overline 2px;
  color: #162038;
}


.load{
  animation: fade-in-out 1s linear infinite alternate;
}
@keyframes fade-in-out{
  to{
      opacity: 0;
  }
}